export enum statuses {
	WAITING = 'odottaa',
	IN_PROGRESS = 'kaynnissa',
	BILLING = 'laskutus',
	READY = 'valmis',
	INSPECTION = 'tarkastuksessa',
	DELETED = 'poistettu'
}

export const statusList = [
	{
		iconName: 'query_builder',
		label: 'Odottaa',
		status: statuses.WAITING
	},
	{
		iconName: 'lens',
		label: 'Käynnissä',
		status: statuses.IN_PROGRESS
	},
	{
		iconName: 'radio_button_checked',
		label: 'Tarkastuksessa',
		status: statuses.INSPECTION
	},
	{
		iconName: 'assignment',
		label: 'Laskutus',
		status: statuses.BILLING
	},
	{
		iconName: 'check_circle',
		label: 'Valmis',
		status: statuses.READY
	}
]

export const defaultUserStatuses = [statuses.IN_PROGRESS]
export const defaultAdminStatuses = [statuses.IN_PROGRESS]
export const statusAllowedForModification = [
	statuses.WAITING,
	statuses.IN_PROGRESS,
	statuses.INSPECTION,
	statuses.BILLING
]

export const statusObject = () => {
	return statusList.reduce(
		(array, value) => ({ ...array, [value.status]: value.label }),
		{}
	)
}
